

.bg_image_service{
    background-image: url('/public/images/8-IT-Solutions.jpg');
    -webkit-filter: blur(2px); 
    filter: blur(0px); 
    max-width: 100%;
    height:70vh;
    object-fit: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg_image_career{
    background-image: url('/public/images/career1.jpg');
    -webkit-filter: blur(2px); 
    filter: blur(2px);
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg_text{
    color:darkslategray;
   
    font-weight: bold;
    border: 3px solid #f1f1f1;
    position:absolute;
    top: 60%;
    left: 30%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%; 
    padding: 20px;
    text-align: center;
}
.bg_text_1{
    /* background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.4); */
    color:darkslategray;
    font-weight: bold;
    /* border: 3px solid #f1f1f1; */
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}
.bg_text_2{
    
    color:rgb(1, 13, 44);
    /* font-weight: bold; */
    line-height: normal;
    position:absolute;
    top: 25%;
    left: 15%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.service_text_1{
    
    color:rgb(1, 13, 44);
    /* font-weight: bold; */
    line-height: normal;
    position:absolute;
    top: 30%;
    left: 7.2%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.service_text_2{
    
    color:rgb(1, 13, 44);
    /* font-weight: bold; */
    line-height: normal;
    position:absolute;
    top: 40%;
    left: 25%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
}

.carousel_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
.hStyle{ color:dimgray}
  
.image-container {
    position: relative;
    display: inline-block;
  }
  
  .image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    text-align: center;
  }
  .app {
    text-align: center;
  }
  
  .footer {
    background-color: #0a090b;
    color: white;
    position:static;
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: "75px";
    margin-top: auto;
    bottom: 0;
  }
  .loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
  .loaded {
    filter: blur(0px);
    transition: filter 0.2s linear;
  }