@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      /* transform: translateZ(-20px); */
    }
    100% {
      opacity: 1;
      transform: translateY(0);
      transform: translateZ(-20px);
    }
  }
  
  .text-container {
    position: relative;
    width: 100%;
  }
  
  .text {
    position: absolute;
    top: 5%;
    left: 10%;
    transform: translate(-50%, -50%);
    animation: fadeIn 5s forwards infinite;
    color:rgb(21, 80, 132)
  }
  
  .image-container1 {
    position: relative;
    display: inline-block;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
    transition: filter 1s ease-in-out;
  }
  
  .image:hover {
    filter: brightness(85%);
  }