#main {
    width :100%;
    height: 500px;
    /* background-color: black; */
}
#main h1{
    font-size:30px;
    text-align: center;
}
#main h2{
    font-size:22px;
    text-align: center;
}

#main .text, .img {
    width:50%;
    height:400px;
    float:left;
    color: antiquewhite;
    /* background-color: black; */
    
    }
    
.text p{
    font-size:22px;
    
    text-align:center;
    
}
.img img{
    width:100%;
    height:auto;
    padding: 10px;
}
 
 @media (max-width:768px) {
#main h1{
    font-size: 22px;  
}
#main h2{
    font-size:16px;
    text-align: center;
}
.text p{
    font-size: 16px;
}
.img img{
    width: 100%;
    height: auto;
    
}
}

@media (min-width:768px) {
    #main h1{
        font-size:22px;
        }
    .text p{
        font-size:18px;
        }
    .img img{
        width: 100%;
        height:30vh;
    }
}
@media (max-width: 480px){
    #main h1{
    font-size:20px;
    }
    
    .text p{
    font-size:16px;
    }

    .img img {
        width:100%;
        height: 350px;
        }
    
    #main .img{
        height:auto;
        }

    #main .img img {
    /* width:100%; */
    height: auto;
    }
    
    #main .text, .img {
    width:100%;
    }
    
    #main .text{
    height:auto;
    }
}